.data_content {
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #e4e4e7;
  background: var(--table-bg-color);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

  h5 {
    padding: 15px 16px 15px 16px;
    color: var(--table-title-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-bottom: 1px solid #e4e4e7;
  }

  .data_table {
    margin-top: 16px;

    .dnuUOg {
      z-index: 0;
    }

    .status {
      border-radius: 2px;
      padding: 5px 10px;
      font-size: 12px;
      padding-left: 20px;
      font-weight: 500;
      text-align: center;
      color: white;
      border-radius: 10px;
      position: relative;
    }

    .status::before {
      position: absolute;
      content: "\f111";
      font-family: fontAwesome;
      left: 7px;
      top: 9px;
      font-size: 8px;
    }

    //blue
    .cancelled,
    .end {
      background: rgb(245, 245, 255);
      color: rgb(20, 20, 175);
    }

    //green
    .completed,
    .enable {
      color: rgb(3, 114, 3);
      background: #def2d0;
    }

    //red
    .pending,
    .pause {
      background: #ffe9e9;
      color: rgb(212, 7, 7);
    }

    //orange
    .archived {
      background: beige;
      color: darkorange;
    }

    .table_content {
      .blue {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }

      .rdt_TableHead {
        z-index: 1 !important;
      }

      .rdt_TableHeadRow {
        min-height: 0;
        padding: 12px 0;
        background: var(--table-header-bg);
        color: var(--table-header-text);
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
      }

      .rdt_TableRow {
        color: var(--table-text-color) !important;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }
    }

    .table_content_orders {
      .rdt_TableRow {
        padding: 15px 0;
      }

      .rdt_TableCell {
        align-items: flex-start;
      }
    }

    .table_content_orders_view {
      .rdt_TableRow {
        padding: 15px 0;
      }

      .rdt_TableCell {
        align-items: flex-start;
      }

      .rdt_TableHeadRow {
        background-color: #e6adad40;
      }

      .rdt_TableCol {
        font-size: 15px;
      }
    }

    .rdt_Pagination {
      background-color: var(--table-pagination-bg-color) !important;
    }
  }
}

.data_content_btn {
  h5 {
    padding: 0;
    border-bottom: none;
  }

  .data_model_btn {
    display: flex;
    border-bottom: 1px solid #e4e4e7;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    align-items: center;

    button {
      border-radius: 2px;
      background: var(--add-bg-color);
      border: none;
      color: var(--add-text-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      display: inline-flex;
      padding: 8px 10px;
      align-items: center;
      gap: 10px;
    }

    .filTypeBox {
      display: flex;
      align-items: center;
    }
  }

  .data_model_btn_button {
    border-radius: 2px;
    border: none;
    background: var(--add-text-color);
    color: var(--add-bg-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    display: inline-flex;
    padding: 6px 10px;
    align-items: center;
    gap: 10px;
    border: 2px solid var(--add-bg-color);
    transition: 1s ease;
  }

  .with_filter {
    display: flex;

    .filter_data {
      display: flex;
    }

    select {
      border: 1px solid lightgray;
    }
  }

  .clicked {
    border: 2px solid var(--add-bg-color);
    background: var(--add-bg-color);
    color: var(--add-text-color);
  }
}

.action_icon {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 420px;

  i.fa.fa-pencil {
    color: green;
    padding-right: 10px;
  }

  i.fa.fa-trash {
    color: red;
  }

  i.fa.fa-sign-in {
    color: blue;
    background: lightblue;
    padding: 10px;
    border-radius: 7px;
  }
}

.superadmin_search_dropdown__control {
  border-radius: 0 !important;
}