.add_modal{
  
    .modal-content {
        border-radius: 0;
    }
    .modal-body {
        padding: 30px;
    }
}
.delete_modal{
    h5 {
        font-size: 18px;
    }
}
