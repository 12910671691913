.footer_container{
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #02468B;
    color:#fff;
    border-left: 1px solid rgba(26, 26, 26, 0.101);
    box-shadow: 0 -1px 1px -1px #0003, 0 0 1px #00000024, 0 -1px 3px #0000001f;
    div{
        padding: 8px;
        margin: 0;
        padding-bottom: 6px;
        #content{
            padding: 10px 30px 10px 30px;
            font: 600 14px/32px Helvetica ,Arial,sans-serif;
            background-color: #051f3835;
            border-radius: 8px;
            height: 100%;
        }
        #title{
            color: #0073e6;
        }
    }
   
}