.chart_select_option {
    select {
        width: 15%;
        border-radius: 5px;
        box-shadow: -8px 12px 18px 0px rgba(25, 42, 70, 0.00);
        border: none;
        padding: 10px;
        outline: none;
        color: #71717A;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
    }
}