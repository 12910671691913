.search_keyword {
  .search_option {
    button {
      background: var(--add-bg-color);
      border: none;
      color: var(--add-text-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      display: inline-flex;
      padding: 8px 10px;
      align-items: center;
      gap: 10px;
      border-radius: 0px 4px 4px 0px;
    }
  }
  .rs-picker-toggle.rs-btn{
    padding: 9px 8px !important;
    outline: none;
    border: 2px solid rgb(226, 226, 226);
    width: 100%;
    border-radius: 3px;
    height: 100%;
  }

  input,
  select {
    border: 2px solid rgb(226, 226, 226);
    padding: 8px;
    outline: none;
    width: 100%;
    border-radius: 3px;
  }

  .border-bottom {
    border-bottom: 1px solid #E4E4E7
  }

  .set_values {
    border-top: 1px solid #E4E4E7;
  //   background-color: black;
  //   opacity: 0.5;
  }

  .value_inputs {
    input {
      height: 41px;
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.data_table {
  margin-top: 16px;

  .dnuUOg {
    z-index: 0;
  }

  .table_content {
    .blue {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }

    .rdt_TableHeadRow {
      min-height: 0;
      padding: 12px 0;
      background: var(--table-header-bg);
      color: var(--table-header-text);
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .rdt_TableRow {
      color: var(--table-text-color) !important;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
    }

  }

  .rdt_Pagination {
    background-color: var(--table-pagination-bg-color) !important;
  }
}