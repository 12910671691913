@media only screen and (max-width: 721px) {
    .login_section .section_1 {
        width: 0;
    }

    .login_section .section_2 {
        width: 100%;
        justify-content: center;
        padding-left: 0;
    }

    .login_section .section_2 .user-box input.user-box-input {
        width: 100%;
    }

   

}