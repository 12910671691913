
@media (min-width: 992px) {
    #wrapper {
      padding-left: 306px;
    }
  
    #wrapper.toggled {
      padding-left: 60px;
    }
  
    #sidebar-wrapper {
      width: 306px;
    }
  
    #wrapper.toggled #sidebar-wrapper {
      width: 60px;
    }
  
    #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -190px;
    }
  
    #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -190px;
    }
  
    #navbar-wrapper {
      position: relative;
    }
  
    #wrapper.toggled {
      padding-left: 60px;
    }
  
    #content-wrapper {
      position: relative;
      top: 0;
    }
  
    #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 60px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    #wrapper {
      padding-left: 60px;
    }
  
    #sidebar-wrapper {
      width: 60px;
    }
  
    #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -306px;
    }
  
    #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -306px;
    }
  
    #navbar-wrapper {
      position: relative;
    }
  
    #wrapper.toggled {
      padding-left: 306px;
    }
  
    #content-wrapper {
      position: relative;
      top: 0;
    }
  
    #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 306px;
    }
  }
  
  @media (max-width: 767px) {
    #wrapper {
      padding-left: 60px;
    }
  
    // #sidebar-wrapper {
    //   width: 0;
    // }
  
    #wrapper.toggled #sidebar-wrapper {
      width: 100%;
    }
    #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -306px;
    }
  
    #wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -306px;
    }
  
    #navbar-wrapper {
      position: relative;
    }
  
    #wrapper.toggled {
      padding-left: 306px;
    }
  
    #content-wrapper {
      position: relative;
      top: 0;
    }
  
    #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 306px;
    }
  }
  
  @media only screen and (max-width: 770px) {
    #sidebar-wrapper {
      width: 60px;
    }
  }