.add_package {
  .modal-body {
    ._53Ji7 {
      padding: 0 !important;
    }

    ._2_g61 {
      padding: 0 !important;
      padding-top: 4rem !important;
    }
  }
}

// -----------------step 1-------------------//
.step_1 {
  .package_table {

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }

    .main_package {
      .tooltip_show {
        svg.tooltip_mark {
          background: black;
          border-radius: 50%;
          margin-left: 5px;
          color: white;
          font-size: 13px;
          padding: 1px;
        }
      }

      .input_box {
        input[type="text"] {
          padding: 3px !important;
          width: 79%;
          margin-right: 10px;
        }
      }
    }
  }
}

.package_btn {
  display: flex;
  justify-content: space-between;

  button.previous {
    background: royalblue;
    color: white;
    padding: 8px;
    margin-right: 15px;
    width: 100px;
  }

  button.next {
    background: royalblue;
    color: white;
    width: 100px;
    padding: 8px;
  }

  button.save {
    background: green;
    color: white;
    width: 100px;
    padding: 8px;
  }
}

.modal-content {
  overflow: hidden;
}

.view_package_design {
  overflow: hidden !important;

  .view_package_design_header {
    position: relative;
    color: rgb(54, 54, 54);
    padding: 10px !important;
    border: 0 !important;
    background: linear-gradient(134deg, #3b84df, #8060aa);
    box-shadow: 0 9px 30px rgba(0, 0, 30, 0.1) !important;

    /* Horizontal offset, vertical offset, blur radius, color */
    .title {
      color: white;

      .package_name {
        font-weight: 700;
        font-size: 24px;
      }

      p {
        margin: 0;
      }
    }

    .profiles {
      position: absolute;
      transform: rotate(-45deg);
      left: -18%;
      top: 5px;
      width: 50%;
      text-align: center;
      overflow: hidden;
      padding: 3px 0 3px 0 !important;
      background-color: white;
      z-index: 9;

      span {
        font-size: 22px;
        font-weight: 600;
      }
    }

    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .modal-body {
    padding: 0;
    box-shadow: inset 0 10px 20px -10px rgb(129, 129, 129);

    .price_div {
      div {
        .amount {
          font-size: 26px;
          font-weight: 600;
          color: #0750aa;

          span {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }


  .sub_packages {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 22px;

    .package_type {
      position: relative;

      .year_name {
        border-radius: 8px;
        background: #0750AA;
        color: white;
        text-align: center;
        padding: 7px;
        width: 103px;
        position: absolute;
        top: -17px;
        left: 42px;
      }

      .sub_price_section {
        border-radius: 12px;
        background: #F2F1FF;
        padding: 25px 50px;

        p {
          font-size: 18px;
        }

        span {
          font-weight: 700;
          color: #0750AA;
        }
      }
    }


  }

  @media only screen and (max-width: 420px) {
    .sub_packages .package_type {

      margin-bottom: 23px;
      width: 80%;
      margin: 10px auto;
    }

    .sub_packages {
      display: block;

    }
  }

  .permission_div {
    width: fit-content;
    margin: 0 auto;

    p {
      margin: 0;
      padding: 2px 0 2px 0;
      font-weight: 500;
      color: #0750aa;
    }

    p::before {
      content: "\f00c";
      font-family: FontAwesome;
      margin-right: 10px;
      color: white;
      border-radius: 50%;
      padding: 3px;
      background: #2d7ad8;
    }
  }

  .type_div {
    text-align: center;
    font-weight: 500;
    background: linear-gradient(134deg, #3b84df, #8060aa);
    color: white;
    box-shadow: 0 10px 20px 10px rgb(199, 199, 199);

    p {
      margin: 0;
    }
  }
}