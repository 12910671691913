

input[type=checkbox],
input[type=radio] {
  accent-color: blue;
}


.assign_campaign_detail {

  .filter_type {
    border: 1px solid lightgray;
    display: grid;
    margin-top: 5px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    button {
      background: none;
      border: none;
      outline: none;
    }

    .filter_name {
      border-right: 1px solid lightgray;
      padding: 8px;
      cursor: pointer;

      text-align: center;
    }

    .active {

      background: cornflowerblue;
      color: white;



    }
  }

  .search_section {
    input.serach_text {
      width: 100%;
      border: 1px solid lightgray;
      padding: 8px;
      margin-top: 5px;
      outline: none;
    }

  }

  .campaign_list {
    margin-top: 5px;

    .select_campaign {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }

    .list {
      border-top: 1px solid lightgray;
      margin-top: 12px;
      height: 500px;
      overflow: scroll;
      overflow-x: hidden;
      padding-top: 12px;
      border: 1px solid lightgray;
      padding: 14px;

    }

    .inner_list {
      display: flex;
      margin-bottom: 3px;
      padding: 4px;

      p {
        font-size: 14px;
      }
    }
  }



  .inner_list:nth-child(odd) {
    background: #f2f2f5;
  }

  .inner_list:nth-child(even) {
    background: white;
  }

  .button.filter_name.display_div {
    border-right: none !important;
  }
}

.list_campaign {

  select,
  input.serach_text {
    width: 100%;
    border: 1px solid lightgray;
    padding: 8px;
    margin-top: 5px;
    outline: none;
  }

  .selected_id {

    .inner_list:nth-child(odd) {
      background: #f2f2f5;
    }

    .inner_list:nth-child(even) {
      background: white;
    }
  }

  .selected_id {
    ul {
      padding: 8px 27px 11px 36px;
    }

    border: 1px solid lightgray;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 16px;

  }

  li.inner_list {
    padding: 6px;
  }

}

.inner_filter {
  select {
    min-width: 264px;
    border: 1px solid lightgray;
    padding: 4px;
    margin-left: 7px;
    outline: none;
  }
}